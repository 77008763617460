@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

$primaryColor: "#66BB6B";
$primaryTextColor: "#0A0A0A";

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

.buttonStyle {
  background-color: $primaryColor;
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

/* Webkit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 5px; /* Radius of the thumb */
}

::-webkit-scrollbar-track {
  background-color: #fff; /* Color of the track */
}

.side_bar {
  height: 56vh;
  overflow-y: auto;
  -ms-overflow-style: none;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: red;
  color: white;
  border-radius: 18px;
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 80%;
  padding: 25px;
  border-radius: 20px;
  height: 90vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.newRoleModale {
  height: 80vh;
  overflow: scroll;
  .accessListCard {
    height: 50vh;
    overflow: scroll;
  }
}

.swal2-center {
  // overflow-y: auto;
  // z-index: 11111;
  // position: absolute;
}

.Toastify {
  z-index: 10000;
}

toast {
  z-index: 10000;
}

.toast-container {
  filter: none !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.companyLogo {
  
}
// .Products_table {
//   thead, tr {
//     display: table;
//     .TD1 {
//       width: 25%;
//     }
//     .TD2 {
//       width: 25%;
//     }
//     .TD3 {
//       width: 25%;
//     }
//     .TD4 {
//       width: 25%;
//     }
//   }
// }

// td { resize: both; overflow: auto; width: 50%; height: 100%; }

// .Products_table {
//   .TR_color {
//     background-color: #F4F7FE;
//   }
// }
